import { useEffect, useState } from 'react';
import SystemInfo from '../System/SystemInfo';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { clearLocalStorage } from 'redux/store';
import { LicenceType } from 'types/auth';
import { oldAppUrlGenerate } from 'utils/data-processors/url';
import './Authenticated.scss';
import {
  removeToken,
  removeTokenFromCookie,
  responseHasTokenError,
} from 'utils/auth/tokenManagement';
import ConnectionListenerWrapper from '../Wrappers/ConnectionListenerWrapper';
import { NavigationWrapper } from 'app/components/Navigation/NavigationWrapper';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { initializeApplicationData } from 'app/components/Authenticated/_config';
import { GlobalModals } from 'app/components/GlobalModals';
import { useScreenSize } from 'hooks/useScreenSize';
import { Box } from '@mui/material';
import useRouteMatcher from 'app/router/hooks/useRouteMatcher';
import paths from 'app/router/paths';
import rootActions from 'redux/actions/app/root';
import FullPageLoader from 'app/components/Loaders/FullPageLoader';
import { actions as applicationActions } from 'redux/slices/applicationSlice';
import authActions from 'redux/actions/auth';
import { actions as openingHoursActions } from 'redux/slices/openingHoursSlice';
import reservationsActions from 'redux/actions/app/reservations';
import appActions from 'redux/actions/app/app';
import { intersection } from 'lodash';

const Authenticated = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    current_date,
    opening_hour: {
      time: { start },
    },
  } = useAppSelector((state) => state.app.app);
  const { isMobile } = useScreenSize();
  const { pathname } = useLocation();
  const isSettingsView = pathname.includes('settings');
  const { routeIs } = useRouteMatcher();
  const user = useAppSelector((state) => state.app.profile);
  const restaurant = useAppSelector((state) => state.app.settings.restaurant);

  const userSettings = useAppSelector((state) => state.auth.userSettings);
  const { isOffline } = useAppSelector((state) => state.application.connectionStatus);

  useEffect(() => {
    dispatch(authActions.getUserSettings())
      .then(async () => {
        if (!isOffline) {
          await initializeApplicationData(dispatch, current_date);
        }
      })
      .catch((error) => {
        const responseCode = error?.data?.status || error?.status;
        if (responseHasTokenError(error)) {
          removeTokenFromCookie();
          removeToken();
          clearLocalStorage();
          navigate(paths.auth.login);
          dispatch(rootActions.resetStoreToDefault());
        }
        if (error?.code >= 500 || responseCode >= 500) {
          dispatch(
            applicationActions.updateConnectionStatus({
              isServerAvailable: false,
              isOffline: true,
            }),
          );
        }
      });
  }, [isOffline]);

  useEffect(() => {
    if (routeIs(paths.home)) {
      navigate(paths.calendar.calendar);
    }
  }, []);

  // Make sure that user have active license and redirect to the settings if have not.
  useEffect(() => {
    if (
      !intersection(userSettings['roles'], ['web developer', 'web admin']) &&
      userSettings.activeLicense &&
      // eslint-disable-next-line
      userSettings.activeLicense.type == LicenceType.nolicence
    ) {
      window.location.href = oldAppUrlGenerate('/settings/subscription', false);
    }
  }, [userSettings]);

  // Update website title.
  useEffect(() => {
    if (restaurant.info?.name) {
      document.title = restaurant.info?.name;
    }
  }, [restaurant]);

  useEffect(() => {
    if (isMobile && !isSettingsView) {
      navigate(paths.calendar.reservationList);
    }
  }, [isMobile]);

  useEffect(() => {
    dispatch(openingHoursActions.fetchOpeningHours());
    dispatch(appActions.updateOpeningHour());
  }, [current_date]);

  useEffect(() => {
    if (start) {
      dispatch(reservationsActions.getFullReservationsForDay());
    }
  }, [start]);

  const [visibleHeight, setVisibleHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setVisibleHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box className="tablein-app" sx={{ height: visibleHeight }}>
      <GlobalModals />
      <SystemInfo />
      <ConnectionListenerWrapper>
        <NavigationWrapper>{user.id === 0 ? <FullPageLoader /> : <Outlet />}</NavigationWrapper>
      </ConnectionListenerWrapper>
    </Box>
  );
};

export default Authenticated;
