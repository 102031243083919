import { Box, Typography, Grid } from '@mui/material';
import { ReactComponent as TimeIcon } from 'images/icons/ic-solid-time.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { timestamp } from 'utils/date-time/timestamp';
import { getAmountDivider } from 'utils/number/getAmountDivider';
import OriginIcon from 'app/components/OriginIcon';
import { DateTimeFormat } from 'utils/date-time/dates';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const iconWrapperStyles = {
  margin: '2px 4px 0 0',
};

export const ReservationNote = () => {
  const { t } = useTranslation();
  const reservation = useAppSelector((state) => state.booking.reservation);
  const dateFormat = useAppSelector((state) => state.app.settings.time_and_language.date_format);
  const timeZone = useAppSelector((state) => state.app.settings.time_and_language.time_zone);

  if (!reservation) {
    return null;
  }

  const {
    created_at,
    updated_at,
    original_start_time,
    special_offer,
    stripe_payment_data,
    origin,
    payments,
  } = reservation;
  const lcfPaidAt = stripe_payment_data?.paidAt
    ? dayjs(stripe_payment_data.paidAt).tz(timeZone)
    : null;
  const lateCancellation = special_offer?.late_cancellation;

  const formattedLateCancellationPaymentDetails = (): string => {
    if (!stripe_payment_data || !stripe_payment_data.paidAmount) return '';

    const amount = getAmountDivider(stripe_payment_data?.paidAmount);
    const currency = lateCancellation?.currency || '';

    return `${t('lateCancellation')} ${amount} ${currency}`;
  };

  const formattedPaymentDetails = (): string => {
    if (!payments || payments.length === 0) return '';

    const payment = payments[0];
    const amount = getAmountDivider(payment.amount);

    return `${t('paid')} ${amount} ${payment.currency} · ${payment.gateway}`;
  };

  const updatedAtIsSameAsCreated =
    dayjs(timestamp(created_at as number).toMilliseconds).format('YYYY-MM-DD HH:mm') ===
    dayjs(timestamp(updated_at as number).toMilliseconds).format('YYYY-MM-DD HH:mm');

  const noteItems = [
    {
      icon: (
        <Box sx={{ margin: '0 2px 0 0' }}>
          <OriginIcon origin={origin} />
        </Box>
      ),
      name: t('reservationNoteReserved'),
      date: created_at,
    },
    {
      icon: (
        <Box sx={iconWrapperStyles}>
          <TimeIcon width={19} height={19} />
        </Box>
      ),
      name: t('reservationNoteLastUpdate'),
      date: updatedAtIsSameAsCreated ? false : updated_at,
    },
    {
      icon: (
        <Box sx={iconWrapperStyles}>
          <TimeIcon width={19} height={19} />
        </Box>
      ),
      name: t('reservationVisitNumber'),
      date: original_start_time,
    },
    {
      icon: (
        <Box sx={iconWrapperStyles}>
          <TimeIcon width={19} height={19} />
        </Box>
      ),
      name: formattedLateCancellationPaymentDetails(),
      date: lcfPaidAt ? lcfPaidAt.unix() : false,
    },
    {
      icon: (
        <Box sx={iconWrapperStyles}>
          <TimeIcon width={19} height={19} />
        </Box>
      ),
      name: formattedPaymentDetails(),
      date: payments && payments[0]?.created,
    },
  ];

  const noteItemsToRender = noteItems
    .filter((item) => item.date)
    .map(({ icon, name, date }) => (
      <Grid item xs={12} md={6} key={name}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', color: 'navyBlue' }}>{icon}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                color: 'navyBlue',
                wordBreak: 'break-word',
              }}
            >
              {name}
            </Typography>
            {date && (
              <Typography sx={{ fontSize: '14px', color: 'comet', wordBreak: 'break-word' }}>
                {dayjs(timestamp(date as number).toMilliseconds)
                  .tz(timeZone)
                  .format(DateTimeFormat(dateFormat))}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    ));

  return (
    <Box
      sx={{
        backgroundColor: 'zircon',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Grid container spacing={2}>
        {noteItemsToRender}
      </Grid>
    </Box>
  );
};
