import { Box, ToggleButton, ToggleButtonGroup, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { CustomLabel } from 'app/components/CustomLabel';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { ReactNode, MouseEvent } from 'react';
import { ColorOption } from 'types/admin/Option';

interface OwnProps {
  name: string;
  label?: string;
  description?: string;
  sx?: SxProps;
  sxToggleButtonGroup?: object;
  sxToggleButton?: object;
  options: ColorOption[];
  isBiggerButton?: boolean;
  required?: boolean;
  errorComponent?: ReactNode;
}

export const ButtonsWithColors = ({
  name,
  label,
  description,
  sx,
  sxToggleButtonGroup,
  sxToggleButton,
  options,
  isBiggerButton,
  required,
  errorComponent,
}: OwnProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue: 'blue',
  });

  const buttons = options || [];

  const handleColorChange = (_: MouseEvent<HTMLElement>, newColor: string) => {
    required && newColor === null ? onChange(value) : onChange(newColor);
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <CustomLabel label={label} name={name} description={description} />
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleColorChange}
        sx={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          '& .MuiToggleButtonGroup-grouped': {
            '&.MuiToggleButton-root': {
              borderLeft: '1px solid',
              border: '1px solid',
            },
          },
          ...sxToggleButtonGroup,
        }}
      >
        {buttons.map(({ label, color, value }, index) => (
          <ToggleButton
            key={index}
            value={value}
            sx={{
              border: `1px solid ${color}`,
              borderRadius: '30px !important',
              color,
              fontSize: '12px',
              lineHeight: 1,
              fontWeight: 600,
              padding: 0,
              minHeight: '24px',
              minWidth: '70px',
              flex: 1,
              textTransform: 'capitalize',
              '&.Mui-selected': {
                backgroundColor: color,
                color: 'white',
                borderColor: 'transparent !important',
                '&:hover': {
                  backgroundColor: color,
                  color: 'white',
                },
              },
              '&:hover': {
                backgroundColor: color,
                color: 'white',
                borderColor: 'transparent !important',
              },
              ...sxToggleButton,
              ...(isBiggerButton && {
                fontSize: '14px',
                borderRadius: '5px !important',
                minHeight: '40px',
                padding: '6px 32px',
                width: 'fit-content',
                flex: 'unset',
              }),
            }}
          >
            {t(label)}
          </ToggleButton>
        ))}
        {errorComponent || <CustomErrorMessage errors={errors} name={name} />}
      </ToggleButtonGroup>
    </Box>
  );
};
