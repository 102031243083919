import { SubStatus as ReservationSubStatus } from 'enums/reservation';
import { ReactComponent as ConfirmedIcon } from 'images/BookingStatuses/ic-confirmed-status.svg';
import { ReactComponent as WalkinIcon } from 'images/BookingStatuses/ic-walkin-status.svg';
import { ReactComponent as BlockedIcon } from 'images/BookingStatuses/ic-blocked-status.svg';
import { ReactComponent as WaitingListIcon } from 'images/BookingStatuses/ic-waiting-list-status.svg';
import { ReactComponent as EventIcon } from 'images/BookingStatuses/ic-event.svg';
import { ReactComponent as TemplateIcon } from 'images/BookingStatuses/ic-template.svg';
import * as yup from 'yup';
import phoneNumberIsValid from 'utils/validation/phoneNumber';
import i18n from 'i18n';
import { Status as ReservationStatus, Type as ReservationType } from 'enums/reservation';

type StatusConfig = {
  [key in ReservationType]?: {
    showGuestDetails: boolean;
    showReservationTags: boolean;
    showExperiences: boolean;
    showFilesAndExtraInformation: boolean;
    showAddGuestDetails: boolean;
    showComments: boolean;
    showGuestNote: boolean;
  };
};

const statusConfig: StatusConfig = {
  [ReservationType.BLOCKED]: {
    showGuestDetails: false,
    showReservationTags: false,
    showExperiences: false,
    showFilesAndExtraInformation: false,
    showAddGuestDetails: true,
    showComments: true,
    showGuestNote: false,
  },
  [ReservationType.WALK_IN]: {
    showGuestDetails: false,
    showReservationTags: true,
    showExperiences: true,
    showFilesAndExtraInformation: true,
    showAddGuestDetails: true,
    showComments: true,
    showGuestNote: false,
  },
};

const bookingModalConfig = (selectedType: ReservationType) => {
  return (
    statusConfig[selectedType] || {
      showGuestDetails: true,
      showReservationTags: true,
      showExperiences: true,
      showFilesAndExtraInformation: true,
      showAddGuestDetails: false,
      showComments: true,
      showGuestNote: true,
    }
  );
};

export const getTypeIcon = (type: ReservationType) => {
  switch (type) {
    case ReservationType.BOOKING:
      return <ConfirmedIcon />;
    case ReservationType.BLOCKED:
      return <BlockedIcon />;
    case ReservationType.WALK_IN:
      return <WalkinIcon />;
    case ReservationType.EVENT:
      return <EventIcon />;
    case ReservationType.WAITING_LIST:
      return <WaitingListIcon />;
    case ReservationType.TEMPLATE:
      return <TemplateIcon />;
    default:
      return null;
  }
};

const bookingFormFieldNames: any = {
  date: 'date',
  guests: 'guests',
  kids: 'kids',
  start_time: 'start_time',
  end_time: 'end_time',
  tables: 'tables',
  waiter_id: 'waiter_id',
  lock_table: 'lock_table',
  tags: 'tags',
  staff_comment: 'staff_comment',
  special_offers: 'special_offer.id',
  files: 'files',
  client: {
    note: 'client.note',
    search: 'client.search',
    name: 'client.name',
    phone: 'client.phone',
    email: 'client.email',
    company: 'client.company',
    subscribed: 'client.subscribed',
    tags: 'client.tags',
    additional_info: {
      allergies: 'client.additional_info.allergies',
      birthday: 'client.additional_info.birthday',
      family_member: 'client.additional_info.family_member',
      favourites: 'client.additional_info.favourites',
      description: 'client.additional_info.description',
    },
  },
  notifications: {
    language: 'notifications.language',
    sms: 'notifications.sms',
    email: 'notifications.email',
  },
  late_cancellation: {
    currency: 'late_cancellation.currency',
    amount: 'late_cancellation.amount',
    description: 'late_cancellation.description',
  },
};

export const disabledTypes = [ReservationType.EVENT, ReservationType.TEMPLATE];
export const disabledSubStatuses = [ReservationSubStatus.REPEAT];

export { bookingModalConfig, bookingFormFieldNames };

export enum AvailableTablesViews {
  Full = 'full',
  Simplified = 'simplified',
}

export const getReservationValidationSchema = (
  lfcMinAmount: number,
  lcfMaxAmount: number,
  lcfMaxDescriptionLength: number,
  lfcCurrency?: string,
) => {
  const { t } = i18n;

  return {
    guests: yup.number().when('$status', {
      is: (status) => status !== ReservationStatus.BLOCKED,
      then: yup.number().positive().typeError('thisFieldIsRequired'),
    }),
    start_time: yup.number().min(1, t('bookingModalStartTimeIsRequired')),
    end_time: yup
      .number()
      .test('test-endDate', t('bookingModalStartTimeBeforeEndTime'), function (end_time) {
        const { start_time } = this.parent;
        return end_time > start_time;
      })
      .required(t('bookingModalEndTimeIsRequired')),
    tables: yup.array().of(yup.number()).min(1, t('bookingModalTableIsRequired')),
    staff_comment: yup.string().nullable().max(1000, t('bookingModalCommentTooLong')),
    client: yup
      .object()
      .shape({
        name: yup.string().required(t('bookingModalGuestNameIsRequired')),
        phone: yup.string().when('$phone_required', {
          is: true,
          then: yup
            .string()
            .transform((phone) => (phone === null ? '' : phone))
            .required(t('bookingModalGuestPhoneIsRequired'))
            .test('phone-number-test', t('invalidPhoneNumber'), phoneNumberIsValid),
          otherwise: yup.string().nullable(),
        }),
      })
      .when('$showGuestDetails', (showGuestDetails, schema) => {
        return !showGuestDetails ? schema.strip(true) : schema;
      }),
    late_cancellation: yup
      .object()
      .nullable()
      .when(['$isLateCancellation', '$status'], {
        is: (isLateCancellation, status) =>
          isLateCancellation &&
          (status === ReservationStatus.NO_SHOW || status === ReservationStatus.CANCELLED),
        then: yup.object({
          amount: yup
            .number()
            .typeError(t('chargeAmountIsRequired'))
            .required(t('chargeAmountIsRequired'))
            .min(
              lfcMinAmount,
              t('chargeMinimumAmountExceeded', { minAmount: lfcMinAmount, currency: lfcCurrency }),
            )
            .max(
              lcfMaxAmount,
              t('chargeMaximumAmountExceeded', { maxAmount: lcfMaxAmount, currency: lfcCurrency }),
            ),
          description: yup
            .string()
            .nullable()
            .max(
              lcfMaxDescriptionLength,
              t('chargeDescriptionTooLong', { maxDescriptionLength: lcfMaxDescriptionLength }),
            ),
        }),
        otherwise: yup.object().strip(true),
      }),
  };
};
