import { Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectReservationSubStatusOptions,
  selectReservationTypeSubStatuses,
} from 'redux/selectors/booking';
import { useEffect } from 'react';
import { actions as bookingActions } from 'redux/slices/bookingSlice';
import { Button } from 'app/components/BookingModal/_components/BookingTopBar/_components/TopBarButton';
import { CustomSelect } from 'app/components/FormElements/CustomSelect';
import { SubStatus as ReservationSubStatus } from 'enums/reservation';
import { Type as ReservationType } from 'enums/reservation';
import { useScreenSize } from 'hooks/useScreenSize';
import { getStatusColor } from 'utils/data-processors/getStatusColors';
import { muiInputWithBorder } from 'styles/constants/inputStyles';
import { useTranslation } from 'react-i18next';
import { disabledSubStatuses } from 'app/components/BookingModal/_config';

type Display = 'buttons' | 'select';

const subStatusLabel = (type: ReservationType) => {
  switch (type) {
    case ReservationType.EVENT:
      return 'eventType';
    case ReservationType.WALK_IN:
      return 'walkInType';
    default:
      return 'type';
  }
};

const subStatusDisplay = (type: ReservationType): Display => {
  switch (type) {
    case ReservationType.EVENT:
      return 'select';
    default:
      return 'buttons';
  }
};

export const SubStatusButtons = () => {
  const { t } = useTranslation();
  const options = useAppSelector(selectReservationSubStatusOptions);
  const typeSubStatuses = useAppSelector(selectReservationTypeSubStatuses);
  const { sub_status, type } = useAppSelector((state) => state.booking);
  const dispatch = useAppDispatch();
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (!sub_status || !typeSubStatuses?.includes(sub_status)) {
      dispatch(bookingActions.setSubStatus(options[0]?.value));
    }
  }, [options]);

  if (!options.length) {
    return null;
  }

  if (subStatusDisplay(type) === 'select' || isMobile) {
    return (
      <CustomSelect
        startAdornment={<Typography sx={{ color: 'comet' }}>{t(subStatusLabel(type))}</Typography>}
        options={options}
        value={sub_status || ''}
        onChange={(event) =>
          dispatch(bookingActions.setSubStatus(event.target.value as ReservationSubStatus))
        }
        sx={{
          backgroundColor: 'brandWhite',
          maxWidth: 'fit-content',
          ...muiInputWithBorder,
        }}
      />
    );
  }

  return (
    <Stack maxWidth={'1180px'} flexDirection={'row'} flexWrap={'wrap'} gap={1}>
      {options.map((option) => {
        const colors = getStatusColor(option.value);
        const isDisabled = disabledSubStatuses.includes(option.value);
        return (
          <Button
            key={option.value}
            disabled={isDisabled}
            value={option.value}
            label={option.label}
            selectedStatus={sub_status || ''}
            onClick={(value) =>
              dispatch(bookingActions.setSubStatus(value as ReservationSubStatus))
            }
            primaryColor={colors.firstColor}
            secondaryColor={colors.secondColor}
          />
        );
      })}
    </Stack>
  );
};
