import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import calendarActions from 'redux/actions/calendar';
import { IStatusesEng, StatusesEng } from 'utils/reservation/reservations';
import { SideModal } from 'app/components/Modals/SideModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Status as ReservationStatus } from 'enums/reservation';

interface OwnProps {
  lateCancellationTime: number | undefined;
}
export const PaymentConfirmationModal = (props: OwnProps) => {
  const status = useAppSelector((state) => state.booking.status);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const modalIsOpen = useAppSelector(
    (state) => state.calendar.lateCancellationNoShowPayment.askForPaymentModalIsOpen,
  );

  const startPayment = () => {
    dispatch(calendarActions.closeLateCancellationNoShowPaymentConfirmationModal());
    dispatch(calendarActions.openLateCancellationNoShowPaymentModal());
  };

  const saveReservationWithoutPayment = () => {
    //@TODO: check if this is needed
    // dispatch(calendarActions.saveReservation());
    dispatch(calendarActions.closeLateCancellationNoShowPaymentConfirmationModal());
  };

  const lateCancellationHours = props.lateCancellationTime
    ? Math.floor(props.lateCancellationTime / 3600)
    : '0';

  return (
    <SideModal
      open={modalIsOpen}
      title={t(StatusesEng[status as keyof IStatusesEng])}
      onClose={() =>
        dispatch(calendarActions.closeLateCancellationNoShowPaymentConfirmationModal())
      }
      withSaveButton={false}
      additionalElements={
        <>
          <Button
            sx={{ height: '40px', lineHeight: 1 }}
            color="secondary"
            variant="outlined"
            onClick={saveReservationWithoutPayment}
          >
            {t('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={startPayment}>
            {t('paymentModalSubmit')}
          </Button>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
        {status === ReservationStatus.NO_SHOW && (
          <Typography sx={{ color: 'comet' }}>
            {t('paymentModalNoShowFeeReservationStatus')}
          </Typography>
        )}

        {status === ReservationStatus.CANCELLED && (
          <Typography sx={{ color: 'comet' }}>
            {t('paymentModalCancelledReservationStatus', {
              hours: lateCancellationHours,
            })}
          </Typography>
        )}
        <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
          {t('paymentModalChargeGuestCard')}
        </Typography>
      </Box>
    </SideModal>
  );
};
